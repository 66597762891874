import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './blog.module.css'
import Next from '../../images/icon/next.svg'
import NextWhite from '../../images/icon/next-white.svg'

const Bg = ({readmore=0}) => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiBlogs(sort: {order: DESC, fields: created_at}, limit:3) {
		    edges {
		      node {
		        slug
		        name
		        excerpt
		        created_at
		        feature_image {
		          childImageSharp {
		            fluid {
		              src
		            }
		          }
		        }
		      }
		    }
		  }
	  }
	`)

	let p = [];
	query.allStrapiBlogs.edges.forEach(({node})=>{
		p.push(node)
	})
	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.titleWrapper}>
						<h1>Blog</h1>
						<p>Read articles about locksmiths</p>
					</div>
					<div className={styles.itemsWrapper}>
					{
						p.map((item, index)=>{
							return (
								<div className={styles.item} key={index}>
									<img src={item.feature_image.childImageSharp.fluid.src} alt={`sks - blog`}/>
									<h3>
										<Link to={`/blog/${item.slug}`}>{item.name}</Link>
									</h3>
									<p>{item.excerpt}</p>
									<div className={styles.tagdateWrapper}>
										<span>{new Date(item.created_at).toLocaleString()} | Smart key</span>
									</div>
								</div>
							)
						})
					}
					</div>
					{
						readmore &&
						<div className={styles.readMore}>
							<Link to="/blog">
								read more 
								<img className={styles.next} src={Next} alt={`sks - blog`}/>
								<img className={styles.nextWhite} src={NextWhite} alt={`sks-next-white`}/>
							</Link>
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default Bg
