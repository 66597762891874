import React from 'react';
import { graphql, useStaticQuery, Link} from "gatsby"

const E = ({styles}) => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiExpertises(sort: {order: DESC, fields: created_at}) {
		    edges {
		      node {
		        feature_image {
		          childImageSharp {
		            fluid {
		              src
		            }
		          }
		        }
		        excerpt
		        slug
		        name
		      }
		    }
		}
	  }
	`)

	let p = [];
	query.allStrapiExpertises.edges.forEach(({node})=>{
		p.push(node)
	})
	return (
		<>
			<div className={styles.expertWrapper}>
			{
				p.map((item,index)=>{
					return(
						<div className={styles.item} key={index}>
							<div className={styles.imgWrapper}>
								<img src={item.feature_image.childImageSharp.fluid.src} alt={`sks - expert`}/>
							</div>
							<h4>{item.name}</h4>
							<p>{item.excerpt}</p>
						</div>
					)
				})
			}
			</div>
		</>
	)
}

export default E