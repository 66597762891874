import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './about.module.css'
import Expertise from './expertise.js'
import Next from '../../../images/icon/next.svg'
import NextWhite from '../../../images/icon/next-white.svg'

const Contact = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiAboutBasicContentManager {
		    edges {
		      node {
		        experiences {
		          description
		        }
		        our_area_expertise_title
		        short_description
		        sub_title
		        title
		      }
		    }
		  }
	  }
	`)

	let p = [];
	query.allStrapiAboutBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<h1>{p.title}</h1>
					<div className={styles.topWrapper}>
						<div dangerouslySetInnerHTML={{__html:p.sub_title}}/>
						<div className={styles.topItemWrapper}>
						{
							p.experiences.map((item, index)=>{
								return(
									<div className={styles.topItem} key={index}>
										<p>
											{item.description}
										</p>
									</div>
								)
							})
						}
						</div>
						<div className={styles.readMore}>
							<Link to="/about-us">
								read more 
								<img className={styles.next} src={Next} alt={`sks - blog`}/>
								<img className={styles.nextWhite} src={NextWhite} alt={`sks-next-white`}/>
							</Link>
						</div>
					</div>
					<div className={styles.bottomWrapper}>
						<h2>{p.our_area_expertise_title}</h2>
						<Expertise styles={styles}/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact