import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import styles from './contact.module.css'
import EmailForm from './email-form'

const Contact = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiContactBasicContentManager {
		    edges {
		      node {
		        title
		        sub_title
		      }
		    }
		}
	  }
	`)

	let p = [];
	query.allStrapiContactBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.contactWrapper}>
						<div className={styles.titleWrapper}>
							<h2>{p.title}</h2>
							<p dangerouslySetInnerHTML={{__html:p.sub_title}}/>
						</div>
						<EmailForm styles={styles}/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact