import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import styles from './banner.module.css'
import ContactInfo from './contact-info'

const Banner = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiHomeBannerBasicContentManager {
		    edges {
		      node {
		        emergancy_service_call_name
		        emergancy_service_call_icon {
		          publicURL
		        }
		        phone_no
		        service_title
		        title
		        description
		        services {
		          name
		          icon {
		            publicURL
		          }
		        }
		      }
		    }
		}
	  }
	`)

	let p = [];
	query.allStrapiHomeBannerBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.bannerTitleWrapper}>
						<h2>
							{p.title}
						</h2>
						<h5>
							{p.service_title}
						</h5>
					</div>
					<div className={styles.bannerInfoWrapper}>
						<div className={styles.infos}>
							<p dangerouslySetInnerHTML={{__html:p.description}}/>
							<ul>
								{
									p.services.map((item, index)=>{
										return(
											<li key={index}>
												<img src={item.icon.publicURL} alt={`sks - keys`}/>
												{item.name}
											</li>
										)
									})
								}
							</ul>
						</div>
						<ContactInfo styles={styles} p={p}/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Banner