import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Banner from '../components/banner/index'
import Contact from '../components/contact/index'
import Blog from '../components/blogs/index'
import Help from '../components/help/index'
import About from '../components/about/home-page/index'

const IndexPage = () => (
  <Layout>
    <SEO title="Home |  Car LockSmith Service Provider"/>
    <Banner/>
    <About/>
    <Help/>
    <Blog readmore={3}/>
    <Contact/>
  </Layout>
)

export default IndexPage
