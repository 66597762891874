import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './help.module.css'
import Banner from '../../images/help-banner.png'

const Help = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiWeCanHelpContentManager {
		    edges {
		      node {
		        title
		        sub_title
		        services {
		          icon {
		            publicURL
		          }
		          name
		        }
		        description
		      }
		    }
		}
	  }
	`)

	let p = [];
	query.allStrapiWeCanHelpContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];

	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.topWrapper}>
						<div className={styles.titleWrapper}>
							<h1>{p.title}</h1>
							<h3>{p.sub_title}</h3>
							<p>{p.description}</p>
						</div>
						<div className={styles.imageWrapper}>
							<img src={Banner} alt={`sks - banner`}/>
						</div>
					</div>
					<div className={styles.bottomWrapper}>
						<ul>
						{
							p.services.map((item,index)=>{
								return(
									<li key={index}>
										<img src={item.icon.publicURL} alt={`sks - bullet`}/>
										<span>{item.name}</span>
									</li>
								)
							})
						}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export default Help